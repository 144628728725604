import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartBand from "../components/ChartBand";


export const PersonalPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | Personal</title>
    </Helmet>
    <div className="hero-section personal-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item dark-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Personal International Transfers, <span>with ease</span>
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  A personal currency account with Swiftfox gives you access to an expert FX trader. They’ll help you to buy and sell currencies to ensure that you save both time and money when exchanging, sending, or receiving currency internationally.
                  <br/><br/>
                  One account, multiple currencies. Everything you need for international banking, including a  swift code, unique IBAN, and more.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button-alt" data-srcoll-to to="#get-started">
                    Get started
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-light">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBand />
            </motion.div>
          )}
        </InView>
      </div>
      <div className="hero-video-overlay" />
      <div className="hero-video">
        <video loop autoPlay muted playsInline>
            <source src="/img/sf-personal.webm" type="video/webm" />
            <source src="/img/sf-personal.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <div className="callout-section dark-bg white-text" id="get-started">
      <div className="callout-main">
        <div className="callout-main-content switcho">
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="Moving Abroad" src="../img/abroad.svg" />
                      <p>Relocating<br/>Abroad</p>
                    </div>
                    <div className="bullet">
                      <img alt="Property Inestments" src="../img/prop.svg" />
                      <p>International <br/>investments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Luxury Items" src="../img/expense.svg" />
                      <p>Purachisng <br/>luxury items</p>
                    </div>
                    <div className="bullet">
                      <img alt="Regular Payments" src="../img/regular.svg" />
                      <p>Receiving foreign<br/>salary payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Working Abroad" src="../img/yen.svg" />
                      <p>Transferring funds <br/>across currencies</p>
                    </div>
                    <div className="bullet">
                      <img alt="Medical Payments" src="../img/medical.svg" />
                      <p>Medical tourism<br/>payments</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>International payments, made easy.</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Whether purchasing a property abroad or buying high-value assets, we ensure that your overseas payments are processed quickly, reliably, and securely.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/open-an-account">
                    Make a transfer
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div> 
      </div>
    </div>
    <div className="callout-section white-bg dark-text">
      <div className="callout-main lessgapok">

      <div className="callout-main-content droppit">
          <div className="section-item">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Why Swiftfox?</span>
                </motion.h2>
              )}
            </InView>
          </div>
        </div>

      <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/bbr.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Bank Beating Rates</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  Our business customers save money when converting currency with a SwiftFox account compared to conversion rates used by other currency exchange services and high street banks
                  </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/regular.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Fast & Secure Payments</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                 Currency Cloud Limited offer international payments to over 180 countries.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>
 



        <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/ts.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Customer Service Focused</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  SwiftFox is a customer service first trading platform, with support and insights available to you seven days a week.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/ipp.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>International Payments Platform</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  All the tools you need for sending and receiving international payments.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>
 


 
        <div className="callout-main-content largerones lessgapwide">
        <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/tran.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Transparency</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  The mid-market rate is always visible, allowing you to see how competitive our rates are, which can be seen on our ‘Live FX’ webpage.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
          
          <div className="section-item white-text fadeytext withimageok">
              <img alt="Regular Payments" src="../img/tpr.svg" />
              <div className="fadeybox">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  <span>Five Star Trustpilot Rating</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  We're proud of what our customers say about us and the recognition we receive for the service we provide. You can find our most recent reviews at the bottom of the page.
                </motion.h4>
              )}
            </InView>
            </div>
          </div>
        </div>
 


 
      </div>
    </div>
    <div className="callout-section orange-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your Swiftfox currency account
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
A Swift Fox currency account allows you to quickly and securely convert from 37 different currencies. 
<br/><br/>
An account provides you with a unique International Bank Account Number (IBAN), allowing you to send and receive payments seamlessly across borders.


                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/open-an-account">
                  Open an account
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="No Hidden Fees" src="../img/fee.svg" />
                      <p>No Hidden Fees</p>
                    </div>
          
                    <div className="bullet">
                      <img alt="No Payment Fees" src="../img/payment.svg" />
                      <p>No Payment Fees</p>
                    </div>

                    <div className="bullet">
                      <img alt="Account Managers" src="../img/manager.svg" />
                      <p>Dedicated Acc. Managers</p>
                    </div>
                    <div className="bullet">
                      <img alt="Payments" src="../img/fast.svg" />
                      <p>Fast & Easy Int. Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Beneficiaries" src="../img/saved.svg" />
                      <p>Pre-Saved Beneficiaries</p>
                    </div>
                    <div className="bullet">
                      <img alt="Trade Status" src="../img/history.svg" />
                      <p>Trade Status & History</p>
                    </div>

                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>Other products we offer</span>
                </motion.h2>
              )}
            </InView>
          <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }} className="button-container">
                  <div className="blue-block section-item">
                    <h4>Spot contracts</h4>
                    <p>Our Spot Contracts allow you to exchange currency instantly. You’ll receive a competitive exchange rate at the time of purchase with a same-day settlement.</p>
                    <Link to="contact-us"  className="main-button-alt">
                    Get in touch to learn more
                    </Link>
                  </div>
                  <div className="grey-block section-item">
                    <h4>Forward Contracts</h4>
                    <p>With a Forward Contract, we agree upon an exchange rate that remains fixed for up to one year. We can give you confidence by agreeing to a rate with us in advance, so you know exactly what you’re future spending will be so that you don’t get caught out by unexpected changes in exchange rates.</p>
                    <Link  to="contact-us"  className="main-button-alt">
                    Get in touch to learn more
                    </Link>
                  </div>
                </motion.div>
              )}
            </InView>
        </div>
      </div>
    </div>


    
  </div>
);

PersonalPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const PersonalPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PersonalPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

PersonalPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PersonalPage;

export const pageQuery = graphql`
  query PersonalPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
